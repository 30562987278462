import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import './styles/stylesheet.scss'

import VueRouter from 'vue-router'
import router from './router'
import store from './store'
import { i18n } from './plugins/i18n.js'
import Gravatar from 'vue-gravatar'
import App from './App.vue'
import Ellipsis from 'vue-directive-ellipsis'
import 'vue-directive-ellipsis/dist/ellipsis.umd.css'
import FlagIcon from 'vue-flag-icon'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter)
Vue.component('v-gravatar', Gravatar)
Vue.directive('ellipsis', Ellipsis)
Vue.use(FlagIcon)

Vue.config.productionTip = false

store.dispatch('fetchConfig').then(() => {
  return new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
