import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '../store/index.js'

import en from '../locales/en.json'
import de from '../locales/de.json'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: import.meta.env.VITE_I18N_LOCALE || store.getters.getLocale || 'en',
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'en',
  messages: {
    en: en,
    de: de
  }
})
