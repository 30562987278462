<template>
  <header id="header">
    <b-navbar variant="light" type="light" fixed="top" role="navigation">
      <b-navbar-brand href="#" :to="{ name: 'timetracking' }">
        <img
          src="../static/logo.png"
          height="50"
          class="d-inline-block align-top"
          alt="Goldflam - Software Solutions"
        />
      </b-navbar-brand>

      <b-navbar-nav id="nav" class="mr-auto">
        <b-nav-item v-if="isLoggedIn" :to="{ name: 'timetracking' }" active-class="active">{{
          $t('timetracking.link')
        }}</b-nav-item>
        <b-nav-item v-if="isAdmin || isProjectManager" :to="{ name: 'validation' }" active-class="active">{{
          $t('validation.link')
        }}</b-nav-item>
        <b-nav-item v-if="isLoggedIn" :to="{ name: 'estimate' }" active-class="active">{{
          $t('estimate.link')
        }}</b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav id="secondary-nav">
        <b-nav-item>
          <LocaleChanger />
        </b-nav-item>

        <b-nav-item-dropdown
          right
          v-if="isAdmin || isProjectManager"
          id="entity-management-item"
          class="mr-2 icon-dropdown"
        >
          <template #button-content>
            <b-icon icon="diagram-3" />
            <b-tooltip target="entity-management-item" :title="$t('settings.entity-management')" triggers="hover" />
          </template>
          <b-dropdown-group :header="$t('settings.entity-management')">
            <b-dropdown-item :to="{ name: 'manage_customers' }">{{ $t('customer.link') }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'manage_projects' }">{{ $t('project.link') }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'manage_tickets' }">{{ $t('ticket.link') }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'manage_ticket_groups' }">{{ $t('ticketGroups.link') }}</b-dropdown-item>
          </b-dropdown-group>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right v-if="isAdmin" id="employee-management-item" class="mr-2 icon-dropdown">
          <template #button-content>
            <b-icon icon="people" />
            <b-tooltip target="employee-management-item" :title="$t('settings.employee-management')" triggers="hover" />
          </template>
          <b-dropdown-group :header="$t('settings.employee-management')">
            <b-dropdown-item :to="{ name: 'manage_users' }">{{ $t('user.link') }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'manage_contracts' }">{{ $t('contract.link') }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'manage_timesheets' }">{{ $t('timesheet.link') }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'manage_absences' }">{{
              $t('absence.vacation-and-absences')
            }}</b-dropdown-item>
          </b-dropdown-group>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right v-if="isAdmin" id="settings-item" class="mr-2 icon-dropdown">
          <template #button-content>
            <b-icon icon="gear" />
            <b-tooltip target="settings-item" :title="$t('settings.settings')" triggers="hover" />
          </template>
          <b-dropdown-group :header="$t('settings.settings')">
            <b-dropdown-item :to="{ name: 'manage_organisation' }">{{ $t('organisation.link') }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'manage_import' }">{{ $t('import.link') }}</b-dropdown-item>
            <b-dropdown-item :to="{ name: 'manage_export' }">{{ $t('export.link') }}</b-dropdown-item>
          </b-dropdown-group>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right v-if="isLoggedIn">
          <template #button-content>
            <b-avatar size="36px" rounded="lg" variant="white">
              <v-gravatar :size="36" default-img="mm" :email="$store.getters.getCurrentUser.email" class="gravatar" />
            </b-avatar>
          </template>
          <b-dropdown-item :to="{ name: 'account_profile' }">{{ $t('profile.link') }}</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'account_timesheet' }">{{ $t('timesheet.link') }}</b-dropdown-item>
          <b-dropdown-item :to="{ name: 'account_absence' }" v-if="isVacationEnabled">{{
            $t('absence.link')
          }}</b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item :to="{ name: 'logout' }">{{ $t('login.logout') }}</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </header>
</template>

<script>
import LocaleChanger from '@/components/LocaleChanger.vue'
import { ApiMixin } from '@/mixins/ApiMixin.js'

export default {
  name: 'AppHeader',
  mixins: [ApiMixin],
  components: { LocaleChanger }
}
</script>

<style lang="scss" scoped>
@import '../styles/_variables';

.nav-item {
  align-self: center;
}

.nav-item.icon-only .nav-link {
  display: inline;
  vertical-align: super;
}

#nav .nav-item .nav-link,
#secondary-nav .nav-item .nav-link {
  color: var(--dark);
}

#nav .nav-item .nav-link {
  border-bottom: 3px solid transparent;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
#nav .nav-item .nav-link {
  color: var(--dark);
}
#nav .nav-item .nav-link.active,
#nav .nav-item .nav-link:active,
#nav .nav-item .nav-link:focus,
#nav .nav-item:hover .nav-link {
  color: $spot-color-blue;
  background-color: initial;
  border-bottom: 3px solid $spot-color-blue;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

#secondary-nav .icon-dropdown {
  margin-top: -6px;
}
</style>

<style lang="scss">
#secondary-nav .icon-dropdown {
  .dropdown-toggle::after {
    vertical-align: 0.07em;
  }
  .dropdown-header {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>
