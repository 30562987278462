// https://stackoverflow.com/a/38552302/1128689

export const JsonWebToken = {
  /**
   * This function parses the given JSON Web Token to retrieve its payload and data.
   * It returns a JSON Object with the two keys:
   * - 'sub' containing the username
   * - 'exp' containing the expiry date as JavaScript Date in seconds
   *
   * @param {string}  token  The JSON Web Token
   */
  parseJwt(token) {
    let base64Url = token.split('.')[1]
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    let jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
    return JSON.parse(jsonPayload)
  },

  /**
   * This function parses the given JSON Web Token and checks its expiry date
   *
   * @param {string}  token  The JSON Web Token
   * @returns {boolean} whether or not the JWT did expire
   */
  isValid(token) {
    return this.parseJwt(token).exp > Date.now() / 1000
  },

  /**
   * This function parses the given JSON Web Token and returns the username string
   *
   * @param {string}  token  The JSON Web Token
   * @returns {string} the username stored in the JWT
   */
  getUsername(token) {
    return this.parseJwt(token).sub
  }
}
